module.exports={
  "name": "yasgui-yasr",
  "description": "Yet Another SPARQL Resultset GUI",
  "version": "2.12.19",
  "main": "src/main.js",
  "license": "MIT",
  "author": "Laurens Rietveld",
  "homepage": "http://yasr.yasgui.org",
  "devDependencies": {
    "bootstrap-sass": "^3.3.7",
    "browserify": "^13.1.0",
    "browserify-transform-tools": "^1.6.0",
    "exorcist": "^0.4.0",
    "gulp": "^3.9.1",
    "gulp-autoprefixer": "^3.1.0",
    "gulp-bump": "^2.2.0",
    "gulp-clean-css": "^2.0.12",
    "gulp-concat": "^2.6.0",
    "gulp-connect": "^4.2.0",
    "gulp-cssimport": "^3.1.0",
    "gulp-delete-lines": "0.0.7",
    "gulp-embedlr": "^0.5.2",
    "gulp-filter": "^4.0.0",
    "gulp-git": "^2.4.1",
    "gulp-html-replace": "^1.6.1",
    "gulp-jsvalidate": "^2.1.0",
    "gulp-livereload": "^3.8.1",
    "gulp-minify-css": "1.2.4",
    "gulp-notify": "^2.2.0",
    "gulp-rename": "^1.2.2",
    "gulp-sass": "^2.3.2",
    "gulp-sourcemaps": "^1.6.0",
    "gulp-streamify": "1.0.2",
    "gulp-tag-version": "^1.3.0",
    "gulp-uglify": "^1.5.4",
    "node-sass": "^3.8.0",
    "require-dir": "^0.3.2",
    "run-sequence": "^1.2.2",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "~1.1.0",
    "vinyl-transform": "1.0.0",
    "watchify": "^3.7.0",
    "yasgui-yasqe": "^2.10.1"
  },
  "bugs": "https://github.com/YASGUI/YASR/issues/",
  "keywords": [
    "JavaScript",
    "SPARQL",
    "Editor",
    "Semantic Web",
    "Linked Data"
  ],
  "scripts": {
    "dev": "gulp serve",
    "build": "gulp",
    "patch": "gulp patch",
    "minor": "gulp minor",
    "major": "gulp major",
    "update-interactive": "npm-check --skip-unused -u"
  },
  "maintainers": [
    {
      "name": "Laurens Rietveld",
      "email": "laurens.rietveld@gmail.com",
      "web": "http://laurensrietveld.nl"
    }
  ],
  "repository": {
    "type": "git",
    "url": "https://github.com/YASGUI/YASR.git"
  },
  "dependencies": {
    "browserify-shim": "^3.8.12",
    "codemirror": "^5.17.0",
    "color": "^1.0.3",
    "colorbrewer": "^1.0.0",
    "colormap": "^2.3.0",
    "d3": "^4.1.1",
    "datatables.net": "^1.10.15",
    "datatables.net-dt": "^1.10.15",
    "debug": "^3.1.0",
    "jquery": "^2.2.4",
    "jquery-ui": "1.10.5",
    "leaflet": "^1.2.0",
    "lodash": "^4.16.1",
    "npm-check": "^5.4.5",
    "pivottable": "^2.1.0",
    "wellknown": "^0.5.0",
    "yasgui-utils": "^1.6.7"
  },
  "browserify-shim": {
    "google": "global:google"
  },
  "browserify": {
    "transform": [
      "browserify-shim"
    ]
  },
  "optionalShim": {
    "codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "jquery": {
      "require": "jquery",
      "global": "jQuery"
    },
    "../../lib/codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "datatables": {
      "require": "datatables",
      "global": "jQuery"
    },
    "d3": {
      "require": "d3",
      "global": "d3"
    },
    "jquery-ui/sortable": {
      "require": "jquery-ui/sortable",
      "global": "jQuery"
    },
    "pivottable": {
      "require": "pivottable",
      "global": "jQuery"
    }
  }
}
